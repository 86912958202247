import React from "react";
import Seo from "../../components/seo";
import Layout from "../../components/layout";

import CodeOfConduct2022 from "../../components/enterpriseConf2022/CodeOfConduct2022";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/enterprise-conf-2022.png",
};

const canonicalUrl = "https://hasura.io/enterprisegraphql/code-of-conduct/";

const CodeOfConduct = props => (
  <Layout location={props.location}>
    <Seo
      title="Code of Conduct | Enterprise GraphQL Conf"
      description="All attendees, speakers, sponsors and volunteers at Enterprise GraphQL Conf 2022 are required to agree with the following code of conduct."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <CodeOfConduct2022 />
  </Layout>
);

export default CodeOfConduct;
