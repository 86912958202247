import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import CopyWriterWithSidebar from "../common/CopyWriterWithSidebar";

import { scrollToTop } from "../common/Header/helper";
import { Icon } from "../../globals/icons";
import { StyledTitle2B, StyledSubTitle1, StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { flexCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";


const StyledDarkThemeWrapper = styled.div`
  background: #0C1015;
  .textGradient {
    background: linear-gradient(285.77deg, #1EB4D4 18.49%, #E8FFB8 107.05%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const StyledCodeOfConduct2022 = styled.div`
  .pb-50 {
    padding-bottom: 50px;
  }
  ${mq.below.md} {
    .pb-50 {
      padding-bottom: 30px;
    }
  }
`

const CodeOfConduct2022 = () => {

  return (
    <StyledDarkThemeWrapper>
      <StyledSectionWrapper>
        <StyledContainerWrapper>
          <StyledCodeOfConduct2022>
            <StyledDesc2 variant="white" linkVariant="white" fontWeight="font_500" className="pb-50">
              <Link to="/enterprisegraphql/" onClick={scrollToTop} css={flexCenter}>
                <Icon className="closeIcon" variant="chevron_left" size="sm" color="white" />Conference Home Page
              </Link>
            </StyledDesc2>
            <StyledTitle2B className="textGradient" paddingBottom="pb24">
              Code of Conduct
            </StyledTitle2B>
            <StyledDesc1 variant="white" paddingBottom="pb24">
              All attendees, speakers, sponsors and volunteers at Enterprise GraphQL Conference 2022 are required to agree with the following code of conduct. Organisers will enforce this code throughout the event. We expect cooperation from all participants to help ensure a safe environment for everybody.<br/><br/>
              In case you have identified a breach of our CoC, please send an email addressed to Rajoshi Ghosh with the subject Reporting CoC Violation for Enterprise GraphQL Conference 2022 immediately to <a href="mailto:events@hasura.io">events@hasura.io</a>
            </StyledDesc1><br/>
            <StyledSubTitle1 className="textGradient" paddingBottom="pb16">The Short Version</StyledSubTitle1>
            <StyledDesc1 variant="white" paddingBottom="pb24">
              Our conference is dedicated to providing a harassment-free conference experience for everyone, regardless of gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion (or lack thereof), or technology choices. We do not tolerate harassment of conference participants in any form. Sexual language and imagery is not appropriate for any conference venue, including talks, workshops, parties, Twitter and other online media. Conference participants violating these rules may be expelled from the conference without a refund at the discretion of the conference organisers.
            </StyledDesc1><br/>
            <StyledSubTitle1 className="textGradient" paddingBottom="pb16">The Long Version</StyledSubTitle1>
            <StyledDesc1 variant="white">
              Harassment includes offensive verbal comments related to gender, gender identity and expression, age, sexual orientation, disability, physical appearance, body size, race, ethnicity, religion, technology choices, sexual images in public spaces, deliberate intimidation, stalking, following, harassing photography or recording, sustained disruption of talks or other events, inappropriate physical contact, and unwelcome sexual attention.<br/><br/>
              Participants asked to stop any harassing behavior are expected to comply immediately.<br/><br/>
              Sponsors are also subject to the anti-harassment policy. In particular, sponsors should not use sexualised images, activities, or other material. Booth staff (including volunteers) should not use sexualised clothing/uniforms/costumes, or otherwise create a sexualised environment.<br/><br/>
              If a participant engages in harassing behavior, the conference organisers may take any action they deem appropriate, including warning the offender or expulsion from the conference with no refund.<br/><br/>
              If you are being harassed, notice that someone else is being harassed, or have any other concerns, please contact a member of conference staff immediately - please see the section below on how to report CoC violations.<br/><br/>
              Conference staff will be happy to assist those experiencing harassment to feel safe for the duration of the conference. We value your attendance.<br/><br/>
              We expect participants to follow these rules at all times during conference events and workshop venues and conference-related social events.<br/><br/>
              Original source and credit: <a href="http://2012.jsconf.us/#/about" target="_blank" rel="noopener noreferrer">http://2012.jsconf.us/#/about</a> & The Ada Initiative<br/><br/>
              This work is licensed under a Creative Commons Attribution 3.0 Unported License
            </StyledDesc1>
          </StyledCodeOfConduct2022>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
      <CopyWriterWithSidebar isDarkMode = {true}/>
    </StyledDarkThemeWrapper>
  );
};

export default CodeOfConduct2022;
